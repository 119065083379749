.kab_box {
  &_blau,
  &_rot {
    padding: 15px;
    background-color: $kab_color_gray;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    .head {
      margin-bottom: 40px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 120%;
        height: 150px;
        left: -10%;
        top: -100px;
        transform: rotate($kab_box_angle);
        z-index: 0;
      }
      > * {
        text-transform: uppercase;
        position: relative;
        z-index: 100;
        color: #fff;
        font-size: 1.8rem;
      }
    }
    &.row {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      .head:after {
        top: -115px;
      }
      &.kab_twolines .head:after{
        top: -90px;
      }
    }
    &.kab_twolines .head:after{
       top: -75px;
    }
    .ap-sidebar-nav {
      margin-bottom: 0;
      li {
        border-top: 1px solid;
        background: transparent;
        &:first-of-type {
          border-top: none;
        }
      }
    } // sidebar-nav
  } // _rot, _blau
  &_blau {
    .head:after {
      background: $kab_color_blue;
    }
    .sidebar-nav li {
      border-color: $kab_color_blue;
      a {
        &:hover, &:active {
          color: $kab_color_blue
        }
      }
    }
  }
  &_rot {
    .head:after {
      background: $main-theme;
    }
    .sidebar-nav li {
      border-color: $main-theme;
      a {
        &:hover, &:active {
          color: $main-theme;
        }
      }
    }
  }
}

.kab_box_teaser {
  background-color: $kab_color_gray;
  padding-top: 15px;
  padding-bottom: 15px;
  .head > * {
    color: $kab_color_blue;
    font-size: 1.4rem;
    height: 2.5rem;
  }
  .text {
    h1, h2, h3, h4 {
      color: $main-theme;
    }
  }
  .ap-image-section {
    //margin-top: -15px;
    .ap-image {
      //margin-top: -2.5rem;
    }
  }
  .btn {
    background-color: $kab_color_blue;
    &:hover, &:active {
      background-color: darken($kab_color_blue, 10);
    }
  }
  &.row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: $screen-md-min) {
  .kab_box_teaser .ap-image-section .ap-image {
    margin-top: -2.5rem;
  }
}
