@media (min-width: $screen-md-min) {
.ap-header {
  background-color: transparent;
  &:after {
    content: '';
    background-color: $kab_color_blue;
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    z-index: -10;
  }
  .mainheader {
    .sitename {
      margin-top: 15px;
      margin-left: 15px;
      h2 {
        font-family: 'PT Serif Caption', serif;
        font-size: 1.5em;
        color: #fff;
        margin-bottom: 0;
      }
    }
    .bis-headgroup > .container {
    }
    .bis-logogroup {
      overflow: hidden;
      margin-bottom: 0;
      &:after {
        content: '';
        background-color: $kab_color_lightred;
        transform: rotate($kab_box_angle);
        position: absolute;
        z-index: -5;
        top: -100px;
        left: -10%;
        width: 120%;
        height: 287px;
      }
      .bis-logo {
        .image-only {
          width: 145px;
          background: white;
          padding: 15px;
          margin: 25px 25px 25px 10px;
        }
        .ap-image-section {
          .ap-image {
            .image-src-box {
              .image-inner-box {
                max-height: none;
              }
            }
          }
        }
        &:after {
          content: '';
          display: block;
          width: 368px;
          height: 30px;
          transform: rotate($kab_box_angle);
          background: url('../kab/img/header_claim.png') no-repeat left top;
          position: absolute;
          top: 140px;
          left: 210px;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      clear: both;
      height: 0;
      visibility: hidden;
    }
  }
  .breadcrumbs {
    border: none;
  }
  .pullnav-css .head-navbar {
    position: relative;
    height: 0;
    top: -265px;
    left: 165px;
    width: auto;
    bottom: auto;
    border-bottom: none;
    .navbar-collapse {
      padding: 0;
      .navbar-nav { // ul
        float: none;
        > li {
          > a {
            background-color: transparent;
            color: #fff;
            padding: 10px 15px;
            &:hover,
            &:active {
              border: none;
              text-decoration: underline;
            }
            &:after {
              content: "\f142";
              font: normal normal normal 14px/1 FontAwesome;
              font-size: inherit;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              display: inline-block;
              margin-left: 14px;
              line-height: 20px;
            }
          }
          &.active,
          &.open {
            > a {
              border: none;
              text-decoration: underline;
            }
          } // .active
        }
      } // .navbar-nav
    } // .navbar-collapse
  } // .head-navbar
} // .ap-header
} // @media

@media (min-width: $screen-lg-min) {
  .ap-header .mainheader .bis-logogroup {
    &:after {
      height: 296px;
    }
    .bis-logo:after {
      top: 155px;
    }
  }
} // @meida
