.kab_slider {
  background: $kab_color_gray;
  padding: 15px;
  .slick-list {
    background-color: #fff !important;
    .slick-slide {
      .ap-slick-slide {
        .visual {
          width: 100%;
          height: auto;
        }
        .caption {
          position: relative;
          background: transparent;
          width: 100%;
          text-align: left;
          padding: 25px;
          left: auto;
          right: auto;
          top: auto;
          bottom: auto;
          strong,
          small {
            text-shadow: none;
          }
          strong {
            color: $main-theme;
            font-size: 1.8rem;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 1em
          }
          .subcaption {
            small {
              color: initial;
              font-size: 1.4rem;
              line-height: 1.5;
              &:nth-of-type(2) {
                color: $kab_color_blue;
                margin-top: .5em;
                &:hover, &:focus {
                  color: $main-theme;
                }
              }
            }
          }
        }
      }
    }
  }
} // .kab_slider
@media (min-width: $screen-md-min) {
  .kab_slider {
    .slick-list {
      .slick-slide {
        .ap-slick-slide {
          .visual {
            width: 50%;
          }
          .caption {
            width: 50%;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  } // .kab_slider
} // @media
